#aboutMe-container {
  display: flex;
}

.about-section-pic {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 35vh;
  width: 30vw;
}

.about-section-text {
  display: flex;
  align-items: center;
  text-align: left;
  overflow: scroll;
  line-height: 1.15em;
  width: 75vw;
  height: 40vh;
  color: #213a50de;
  padding: 2em;
  padding-right: 6em;
  font-size: 1.25em;
}

#technologies-icons-parent-div {
  background: linear-gradient(#75819c, #ffffffcc);
  padding: 1.4em;
  height: 48vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 2px 0px 0.5px 0 rgba(32, 49, 55, 0.341) inset;
}

#front-end-vectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

#back-end-vectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

#tools-vectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.svg-box {
  display: flex;
  align-items: center;
}

svg {
  height: 150px;
  width: 150px;
  margin: 0 4em;
}

.text {
  margin: 5em;
  font-size: 1.25em;
}

#headshot-image {
  height: 80%;
  border-radius: 50%;
}

@media screen and (max-width: 800px) {
  #aboutMe-container {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .about-section-text {
    font-size: 1.5em;
    padding: 1em;
  }

  svg {
    height: 4em;
    width: 4em;
    margin: 2em;
  }

  #technologies-icons-parent-div {
    height: 100vh;
    padding-bottom: 3em;
    overflow: scroll;
  }
}
