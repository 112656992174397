#resume-page-container {
  height: 80vh;
  width: 100vw;
  background: linear-gradient(to bottom, #6e6e6e, #ffffff);
  color: black;
}

#button-container {
  width: 100%;
  height: 9vh;
  display: grid;
  grid-template-columns: 30vw 40vw 30vw;
  align-items: center;
}

#button-container > button {
  margin: 0 auto;
  /* padding: 1.5em; */
  font-size: 1.5em;
}

.inactive-button {
  cursor: none;
  background-color: white;
}

button {
  margin: 1em;
  width: 50%;
  margin: 0 auto;
  padding: 1em;
}

.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#download-icon {
  margin-left: 5em;
  color: #ffffff;
  text-decoration: none;
}

#download-icon > svg {
  font-size: 20px;
  height: 40px;
  width: 40px;
  flex-direction: column;
  align-items: center;
}

#download-icon-text {
  margin-left: 5em;
  color: black;
  text-decoration: none;
}

#download-icon-text :hover {
  color: black;
}

#download-icon :hover {
  color: black;
}

@media screen and (max-width: 800px) {
  #download-icon,
  #download-icon-text {
    font-size: 1.5em;
    margin: 0 auto;
  }
  #resume-page-container {
    /* height: 100vh;
        width: 100vw; */
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }
}
