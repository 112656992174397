#email-container {
  height: 80vh;
  width: 100vw;
  background: linear-gradient(#75819c, #fefefe38) bottom no-repeat;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#email-container > form {
  width: 35%;
  margin-top: 4em;
}

.contact-icon-div {
  font-size: 2.5em;
  width: 55%;
  height: max-content;
}

.contact-card-title {
  font-size: 0.85em;
  background: linear-gradient(to bottom right, #375360, #94949458);
  border-radius: 8px;
  padding: 1.25em;
  color: white;
}

.contact-credentials,
.contact-credentials a {
  font-size: 1.25em;
  margin-top: 0.5em;
  text-decoration: none;
  color: white;
}
.contact-credentials a :hover {
  cursor: pointer;
}

#contact-form-button {
  width: 50%;
  background-color: #a0957b;
  border-color: #fff;
  box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 2.25em;
  font-weight: 800;
  margin-top: 0.5em;
}
#link-icons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.75em;
}

a > svg {
  margin: 0;
  color: white;
  padding: 0.25em;
}

#strava-icon :hover {
  color: #fc5304;
  background-color: rgba(255, 255, 255);
  border-radius: 8px;
}

#linkedin-icon :hover {
  color: #0b66c2;
  background-color: rgba(255, 255, 255);
  border-radius: 8px;
}

#github-icon :hover {
  color: #171c21;
  background-color: rgba(255, 255, 255);
  border-radius: 8px;
}

.form-label {
  line-height: 1.5em;
}

.contact-card-title > svg {
  height: 1.5em;
  width: 1.5em;
  margin: 0.5em;
  /* color:  rgba(252, 107, 134, 0.81); */
}

@media screen and (max-width: 800px) {
  .contact-icon-div {
    margin-top: 1em;
  }
  #email-container {
    font-size: 0.5em;
    padding-bottom: 8em;
  }
  a > svg {
    height: 1.5em;
    width: 1.5em;
  }

  #email-container > form {
    width: 80%;
  }
}
