#portfolio {
  width: 100vw;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.page_header {
  color: #fefeffde;
  padding-top: 0.35em;
  text-shadow: 1.5px 1.5px 2px rgba(218, 242, 249, 0.32);
  font-size: 4.5em;
}

.page_sub_header {
  color: rgba(229, 231, 232, 0.9);
  font-size: 2.25em;
  padding: 1.5em;
}

.project-image {
  transform: scale(0.95);
}

.project-image:hover {
  /* opacity: .75; */
  transform: scale(1.1);
  overflow: hidden;
}

/* SELECTED PROJECT */
.selected-project-description-div {
  overflow: scroll;
}

.selected-project-card-container {
  height: 90vh;
  width: 100vw;
  background: linear-gradient(to bottom, #332e27aa, #ffffff);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  scroll-behavior: auto;
  overflow: hidden;
}

.selected-project-card-container > div,
.selected-project-card-container > img {
  margin: 2em;
}

.selected-project-description-div {
  background-color: rgba(7, 7, 7, 0.556);
  border-radius: 8px;
  color: white;
  font-size: 1.25em;
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  width: 35vw;
}

.selected-project-role {
  white-space: pre-wrap;
  content: '\A';
}

.selected-project-image {
  max-height: 60vh;
  max-width: 45vw;
  border-radius: 4px;
}

.selected-project-link-button {
  margin: 1em;
}

.selected-project-link {
  height: 100%;
  width: 100%;
  color: white;
}

@media screen and (max-width: 800px) {
  .project-image {
    height: 20%;
    width: 100%;
  }

  .selected-project-card-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .selected-project-description-div {
    font-size: 1.25em;
    padding: 0.5em;
    width: 95vw;
  }
  .selected-project-image {
    border-radius: 4px;
    width: 90vw;
  }
}
