#navbar {
  display: grid;
  grid-template-columns: 20vw 60vw 20vw;
  height: 9.8vh;
  align-items: center;
  top: 0;
  min-width: 100vw;
  max-width: 100vw;
  -webkit-box-shadow: 0 2px #7b91bc;
  -moz-box-shadow: 0 2px #8496bb;
  box-shadow: 0 -2px 2.5px #282c3457 inset;
}

.links-1 {
  display: flex;
  flex: 1;
  justify-content: space-around;
  /* min-width: 50vw; */
  left: 20%;
}

.links-2 {
  display: flex;
  justify-content: space-around;
  flex: 2;
  /* min-width: 50vw; */
}

.links-1 > a,
.links-2 > a {
  color: black;
  text-decoration: none;
  font-size: 1.5em;
  /* margin: 1em; */
  background: linear-gradient(rgba(129, 129, 129, 0.5), rgba(129, 129, 129, 0.5)) left
    bottom / 0 0.8em no-repeat;
  transition: 0.5s background-size;
}

.links-1 > a:hover,
.links-2 > a:hover {
  background-size: 100% 0.1em;
  text-decoration: none;
}

.links-1 > a:visited,
.links-2 > a:visited {
  color: black;
  text-decoration: none;
}
.hamburger-menu-container {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: column;
}
#hamburger-icon-button > svg {
  height: 2em;
  width: 2em;
}
#dropdown-menu {
  position: absolute;
  top: 11vh;
  right: 0;
  font-size: 2em;
  line-height: 1.5em;
  padding: 0 1em 1em 1em;
  border-radius: 0.5em;
  z-index: 999999999;
  background: linear-gradient(white, rgb(203, 202, 202)) right bottom;
}
#dropdown-menu > li {
  list-style: none;
  color: black;
  text-decoration: none;
  transition: 0.5s background-size;
  z-index: 999999999;
}
li > a:visited {
  color: black;
  opacity: 0.8;
}
li > a {
  color: black;
  z-index: 9999;
}

.hamburger-dropdown-enter {
  opacity: 0;
}
.hamburger-dropdown-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.hamburger-dropdown-exit {
  opacity: 1;
}
.hamburger-dropdown-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@media screen and (max-width: 800px) {
  #navbar {
    width: 100vw;
  }

  #navbar > .links-1 {
    font-size: 0.75em;
  }
}
