.App {
  text-align: center;
  max-width: 100vw;
  /* display: block; */
  /* overflow-wrap: normal; */
  color: #525b5f ;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@media screen and (max-width: 800px) {
  #homepage-container,
  #portfolio-container,
  #email-container,
  #resume-page-container,
  #aboutMe-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: scroll;
  }
}


/* FOOTER MEDIA QUERY */
@media screen and (max-width: 800px) {
  .footer-parent-div {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: -5vh;
    /* left: 0; */
    font-size: 1.25em;
    /* color: #ffffff; */
    width: 100vw;
    height: 20vh;  }

    .footer-parent-div > .loading-io-attribution {
      width: 0;
    }
}
